import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import * as Permissions from 'expo-permissions';
import QRCode from 'react-native-qrcode-svg';
import * as firebase from 'firebase';
import React from 'react';
import {
  ActivityIndicator,
  Button,
  TouchableOpacity,
  Clipboard,
  Image,
  Share,
  StatusBar,
  StyleSheet,
  Text,
  TextInput, 
  View,
  Alert,
} from 'react-native';
import uuid from 'uuid';

const firebaseConfig = {
  apiKey: "AIzaSyB1S40NEoizXzD2S_pClSS1xCzBJjmo4Gk",
  authDomain: "phoenix-221611.firebaseapp.com",
  databaseURL: "https://phoenix-221611.firebaseio.com",
  projectId: "phoenix-221611",
  storageBucket: "phoenix-221611.appspot.com",
  messagingSenderId: "602904638998",
  appId: "1:602904638998:web:30e28a994aa2e2a294e737",
  measurementId: "G-L5LR5NBD48"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Firebase sets some timeers for a long period, which will trigger some warnings. Let's turn that off for this example
console.disableYellowBox = true;

export default class App extends React.Component {
  svg;
  state = {
    image: null,
    uploading: false,
    text: ''
  };

  async componentDidMount() {
    await Permissions.askAsync(Permissions.CAMERA_ROLL);
    await Permissions.askAsync(Permissions.CAMERA);
  }

  render() {
    let { image } = this.state;

    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {!!image && (
          <Text
            style={{
              fontSize: 20,
              marginBottom: 20,
              textAlign: 'center',
              marginHorizontal: 15,
            }}>
          </Text>
        )}

        <Button
          onPress={this._pickImage}
          title="Envoyer un fichier"
        />
        <br/>
        <Button onPress={this._takePhoto} title="Prendre une photo" />
        <br/>
        <TextInput
          style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
          value={this.state.text}
          onChangeText={text => this.setState({ text })}
          ref={input => { this.textInput = input }}
        />
        
        {this._maybeRenderImage()}
        {this._maybeRenderUploadingOverlay()}

        <StatusBar barStyle="default" />
      </View>
    );
  }

  _maybeRenderUploadingOverlay = () => {
    if (this.state.uploading) {
      return (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: 'rgba(0,0,0,0.4)',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}>
          <ActivityIndicator color="#fff" animating size="large" />
        </View>
      );
    }
  };

  _maybeRenderImage = () => {
    let { image } = this.state;
    let { text } = this.state;
    let logoFromFile = require('./logo_fond_blanc_50x50.png');
    //console.log(this.state);
    if (!image && text == '') {
      return;
    }

    if (text != '') {
      //console.log(text)
      return (
        <View
          style={{
            marginTop: 30,
            width: 250,
            borderRadius: 3,
            elevation: 2,
          }}>
          <TouchableOpacity onPress={() =>  this.saveQrtoDisk()}>
            <QRCode
              value={text}
              color={'#D14125'}
              backgroundColor={'white'}
              size={250}
              logo={logoFromFile}
              logoSize={50}
              logoBackgroundColor={'transparent'}
              getRef={c => (this.svg = c)}
            />
          </TouchableOpacity>    
        </View>
      );      
    }

    return (
      <View
        style={{
          marginTop: 30,
          width: 250,
          borderRadius: 3,
          elevation: 2,
        }}>
        <View
          style={{
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3,
            shadowColor: 'rgba(0,0,0,1)',
            shadowOpacity: 0.2,
            shadowOffset: { width: 4, height: 4 },
            shadowRadius: 5,
            overflow: 'hidden',
          }}>
          <Image source={{ uri: image }} style={{ width: 250, height: 250 }} />
        </View>
        <Text
          onPress={this._copyToClipboard}
          onLongPress={this._share}
          style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
          {image}
        </Text>
        <TouchableOpacity onPress={() =>  this.saveQrtoDisk()}>
          <QRCode
            value={image}
            color={'#D14125'}
            backgroundColor={'white'}
            size={250}
            logo={logoFromFile}
            logoSize={50}
            logoBackgroundColor={'transparent'}
          />
        </TouchableOpacity>        
      </View>
    );
  };

  saveQrtoDisk = () => {
    const SvgToPng = require('save-svg-as-png');
    SvgToPng.saveSvgAsPng(document.getElementsByTagName('svg')[0], 'qrcode.png');
  };

  _share = () => {
    Share.share({
      message: this.state.image,
      title: 'Check out this photo',
      url: this.state.image,
    });
  };

  _copyToClipboard = () => {
    Clipboard.setString(this.state.image);
    alert('Copied image URL to clipboard');
  };

  _takePhoto = async () => {
    this.textInput.clear();
    this.setState({ text: '' });
    let pickerResult = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [4, 3],
    });

    this._handleImagePicked(pickerResult);
  };

  _pickImage = async () => {
    this.textInput.clear();
    this.setState({ text: '' });
    let pickerResult = await DocumentPicker.getDocumentAsync({
      //allowsEditing: true,
      //aspect: [4, 3],
    });

    this._handleImagePicked(pickerResult);
  };

  _handleImagePicked = async pickerResult => {
    try {
      this.setState({ uploading: true });

      if (!pickerResult.cancelled) {
        var uploadUrl = await uploadImage(pickerResult.uri)
        this.setState({ image: uploadUrl });
      }
    } catch (e) {
      console.log(e);
      alert('Upload failed, sorry :(');
    } finally {
      this.setState({ uploading: false });
    }
  };
}

async function uploadImage(uri) {
  const response = await fetch(uri);
  const blob = await response.blob();

  const ref = firebase
  .storage()
  .ref()
  .child(uuid.v4());

  const snapshot = await ref.put(blob);
  return await snapshot.ref.getDownloadURL();
}